<template>
  <v-container>
    <v-row class="ml-4">
      <v-col>
        <div class="d-flex mt-4">
          <v-select
            label="Agrupar Por"
            class="pr-10"
            :items="[
              {
                text: 'Familia de Elemento de Costo',
                value: 'costItemFamilyName',
              },
              { text: 'Periodo', value: 'periodType' },
              { text: 'Elemento', value: 'directSliceCostItemName' },
            ]"
            v-model="groupKey"
          >
          </v-select>

          <v-select
            label="Ordenar por"
            class="pr-10"
            @change="sortByKey"
            :items="[
              { text: 'Periodo', value: 'costInputPeriodId' },
              { text: 'Natural', value: 'costItemNaturalOrder' },
              { text: 'Costo', value: 'itemCost' },
            ]"
            v-model="sortKey"
          >
          </v-select>
        </div>

        <h1>
          {{ singleDraftActivitySlice.engagement.establishment.name }}
        </h1>
        <div
          class="mt-4 mb-2"
          v-for="(list, index) in groupedItems"
          :key="index"
        >
          <v-row>
            <h2 class="ml-4 grey--text darken-2">{{ list[0][groupKey] }}</h2>
          </v-row>
          <v-row>
            <v-col
              sm="3"
              md="3"
              lg="2"
              xl="2"
              v-for="item in list"
              :key="item.id"
            >
              <v-card class="mb-2">
                <v-card-text>
                  <div class="d-flex flex-column">
                    <div class="primary--text">
                      {{ item.costItemFamilyName }}
                    </div>
                    <div>
                      {{ item.directSliceCostItemName }}
                    </div>
                    <div class="grey--text accent-2">
                      {{ item.periodType }}
                    </div>
                    <div>${{ localify(item.itemCost) }} USD</div>
                    <div class="d-flex align-center">
                      ${{ localify(item.unitPrice * item.unitsPerHectare) }}
                      USD/HA

                      <v-icon
                        v-if="item.random > randomThresh"
                        class=""
                        color="green"
                        x-small
                      >
                        mdi-arrow-up
                      </v-icon>
                      <v-icon
                        v-if="item.random < randomThresh"
                        class=""
                        color="error"
                        x-small
                      >
                        mdi-arrow-down
                      </v-icon>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mt-5 mb-5"></v-divider>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.gradient-background {
  background: rgb(37, 2, 106);
  background: linear-gradient(
    326deg,
    rgba(37, 2, 106, 1) 2%,
    rgba(33, 150, 243, 1) 67%
  );
}
</style>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},

  computed: {
    ...mapGetters(["singleDraftActivitySlice"]),
    groupedItems() {
      let grouping = this.groupKey;

      let items = this.singleDraftActivitySlice.assignedSCostItems;

      // Array of unique groups
      let groups = [...new Set(items.map((item) => item[grouping]))].sort();

      // Array of array of items
      let groupedItems = [];

      groups.forEach((g) =>
        groupedItems.push(
          items
            .filter((i) => i[grouping] === g)
            .map((d) => {
              return { ...d, random: Math.random() };
            })
        )
      );
      return groupedItems;
    },
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch("getAllCostItems"),
      this.$store.dispatch("fetchFinancialPolicies"),
      this.$store.dispatch("getActivities"),
      this.$store.dispatch("fetchSliceCostInputPeriods"),
      this.$store.dispatch("fetchInterestRates"),
      this.$store.dispatch("fetchOtherTonCostItems"),
    ]);

    await this.$store.dispatch("getSingleDraftActivitySlice", 1);
  },
  methods: {
    changeSortKey(event) {
      console.log(event);
    },
    localify(data) {
      return data?.toLocaleString("en-US", {
        timeZone: "America/Argentina/Buenos_Aires",
      });
    },
    async sortByKey(key) {
      await this.$store.dispatch("sortDraftActivitySliceCostItems", key);
    },
  },
  data() {
    return {
      foo: null,
      sortKey: "itemCost",
      groupKey: "periodType",
      randomThresh: 0.3,
    };
  },
};
</script>
