var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"ml-4"},[_c('v-col',[_c('div',{staticClass:"d-flex mt-4"},[_c('v-select',{staticClass:"pr-10",attrs:{"label":"Agrupar Por","items":[
            {
              text: 'Familia de Elemento de Costo',
              value: 'costItemFamilyName',
            },
            { text: 'Periodo', value: 'periodType' },
            { text: 'Elemento', value: 'directSliceCostItemName' } ]},model:{value:(_vm.groupKey),callback:function ($$v) {_vm.groupKey=$$v},expression:"groupKey"}}),_c('v-select',{staticClass:"pr-10",attrs:{"label":"Ordenar por","items":[
            { text: 'Periodo', value: 'costInputPeriodId' },
            { text: 'Natural', value: 'costItemNaturalOrder' },
            { text: 'Costo', value: 'itemCost' } ]},on:{"change":_vm.sortByKey},model:{value:(_vm.sortKey),callback:function ($$v) {_vm.sortKey=$$v},expression:"sortKey"}})],1),_c('h1',[_vm._v(" "+_vm._s(_vm.singleDraftActivitySlice.engagement.establishment.name)+" ")]),_vm._l((_vm.groupedItems),function(list,index){return _c('div',{key:index,staticClass:"mt-4 mb-2"},[_c('v-row',[_c('h2',{staticClass:"ml-4 grey--text darken-2"},[_vm._v(_vm._s(list[0][_vm.groupKey]))])]),_c('v-row',_vm._l((list),function(item){return _c('v-col',{key:item.id,attrs:{"sm":"3","md":"3","lg":"2","xl":"2"}},[_c('v-card',{staticClass:"mb-2"},[_c('v-card-text',[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(item.costItemFamilyName)+" ")]),_c('div',[_vm._v(" "+_vm._s(item.directSliceCostItemName)+" ")]),_c('div',{staticClass:"grey--text accent-2"},[_vm._v(" "+_vm._s(item.periodType)+" ")]),_c('div',[_vm._v("$"+_vm._s(_vm.localify(item.itemCost))+" USD")]),_c('div',{staticClass:"d-flex align-center"},[_vm._v(" $"+_vm._s(_vm.localify(item.unitPrice * item.unitsPerHectare))+" USD/HA "),(item.random > _vm.randomThresh)?_c('v-icon',{attrs:{"color":"green","x-small":""}},[_vm._v(" mdi-arrow-up ")]):_vm._e(),(item.random < _vm.randomThresh)?_c('v-icon',{attrs:{"color":"error","x-small":""}},[_vm._v(" mdi-arrow-down ")]):_vm._e()],1)])])],1)],1)}),1)],1)}),_c('v-divider',{staticClass:"mt-5 mb-5"})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }